
























































































































































































































































































import {
  SlimChannelInfo,
  TargetState,
  TestChannelStateCode
} from '@/includes/types/Board.types'
import { BoardsApi } from '@/includes/Api/Boards.api'
import { ChannelService } from '@/includes/services/ChannelService'
import ChannelCard from '@/components/ChannelCard.vue'
import TestStateInfoModal from '@/components/TestStateInfoModal.vue'
import TestStateMixin from '@/mixins/TestStateMixin'
import { errorNotification } from '@/includes/services/NotificationService'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    ChannelCard,
    TestStateInfoModal,
    EmptyData,
    HelpMessage,
    HighlightAnchor
  },
  data() {
    return {
      TargetState,
      EntityTypes
    }
  }
})
export default class BoardChannels extends Mixins<TestStateMixin>(TestStateMixin) {
  allChannels: Array<SlimChannelInfo> | null = null

  activeChannelId: number | null = null

  isModalOpen = false

  isLoading = false

  get inactiveChannels(): Array<SlimChannelInfo> {
    return (this.allChannels ?? [])
      .filter((channel) => channel.state === TargetState.None)
  }

  get boardChannels(): Array<SlimChannelInfo> {
    return (this.allChannels ?? [])
      .filter((channel) => channel.state === TargetState.Board)
      .sort((a, b) => b.members_count - a.members_count)
  }

  get logChannel(): Array<SlimChannelInfo> {
    return (this.allChannels ?? [])
      .filter((channel) => channel.state === TargetState.Log)
  }

  handleOnChannelStateButtonClick(channelId: number): void {
    this.activeChannelId = channelId
    this.isModalOpen = true
  }

  handleUpdateChannelTestState(channelInfo: { state: TestChannelStateCode, channelId: number, boardId: string }): void {
    if (this.allChannels) {
      const channel = this.allChannels.find(c => c.id === channelInfo.channelId)

      if (channel) {
        channel.test_state = channelInfo.state
      }
    }
  }

  goToByRouteName(routeName: string): void {
    this.$router.push({
      name: routeName,
      params: {
        id: this.$store.getters.activeBoard!.board.split('.')[0]
      }
    })
  }

  updateChannelState(new_state: TargetState): void {
    if (this.activeChannelId) {
      this.isModalOpen = false
      this.isLoading = true

      ChannelService.updateChannelState('tg', {
        board_key: this.$store.getters.activeBoard.board,
        channel_id: this.activeChannelId,
        new_state: new_state
      })
        .then(() => this.getBoardChannels())
        .catch(errorNotification)
        .finally(() => this.isLoading = false)
    }
  }

  async getBoardChannels(): Promise<void> {
    try {
      this.isLoading = true
      const { channels } = await BoardsApi.getBoardChannels('tg', { board_key: this.$store.getters.activeBoard.board })
      this.allChannels = channels
    } catch (error: any) {
      errorNotification(error)
    } finally {
      this.isLoading = false
    }
  }

  deleteChannelWarn(id: number) {
    this.$confirm({
      title: this.$t('delete_channel_warn_title'),
      content: this.$t('delete_channel_warn_text'),
      onOk: () => this.deleteChannel(id)
    })
  }

  deleteChannel(id: number) {
    this.$baseTemplate.loader.open()

    ChannelService.deleteChannel('tg', {
      board_key: this.$store.getters.activeBoard.board,
      id
    })
      .then(() => {
        this.allChannels = (this.allChannels ?? []).filter(c => c.id !== id)
      })
      .catch(errorNotification)
      .finally(() => {
        this.$baseTemplate.loader.close()
      })
  }

  mounted() {
    this.getBoardChannels()
  }
}
